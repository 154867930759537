var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-x-auto"},[_c('transition-group',{attrs:{"type":"transition","name":!_vm.drag ? 'flip-list' : null}},[_c('v-data-table',{key:"table",staticClass:"wsHoverLight",class:[{'pb-4' : !_vm.noFooter}],attrs:{"items":_vm.items,"headers":_vm.headersFiltered,"search":_vm.search,"options":{
         page : _vm.page
       },"items-per-page":4,"footer-props":{ itemsPerPageOptions : [25,50,100,-1]},"hide-default-header":"","hide-default-footer":"","disable-pagination":_vm.disablePagination,"dense":_vm.dense},on:{"current-items":function($event){_vm.$emit('current-items' , $event); _vm.currentItems = $event},"pagination":_vm.handlePaginationData},scopedSlots:_vm._u([{key:"header",fn:function(ref){
       var props = ref.props;
       var on = ref.on;
return [_vm._t("header",[(!_vm.noHeader)?_c('ws-table-header',{attrs:{"props":props,"on":on,"selectable":_vm.selectable,"all-selected":_vm.allSelected,"semi-selected":_vm.selectedRows.length > 0,"enable-drag":_vm.enableDrag,"multiple-actions":_vm.multipleActions,"colspan-value":_vm.colspanValue,"count-selected":_vm.countSelected,"count-total":_vm.countTotal},on:{"select-all":_vm.selectAllOnPage},scopedSlots:_vm._u([{key:"header.after",fn:function(){return [_vm._t("header.after",null,{"colspanValue":_vm.colspanValue})]},proxy:true}],null,true)}):_vm._e()],{"props":props,"on":on,"currentItems":_vm.currentItems,"selectable":_vm.selectable,"allSelected":_vm.allSelected,"semiSelected":_vm.selectedRows.length,"enableDrag":_vm.enableDrag,"multipleActions":_vm.multipleActions,"colspanValue":_vm.colspanValue,"countSelected":_vm.countSelected,"countTotal":_vm.countTotal})]}},{key:"body",fn:function(ref){
       var items = ref.items;
return [_c('draggable',_vm._b({attrs:{"forceFallback":true,"empty-insert-threshold":120,"tag":"tbody","handle":".tableDragHandle"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false; _vm.finishDrag($event)}},model:{value:(_vm.currentItems),callback:function ($$v) {_vm.currentItems=$$v},expression:"currentItems"}},'draggable',_vm.opt,false),[(!_vm.enableDrag)?[(_vm.selectable && _vm.selectedRows.length > 0)?_c('tr',[_c('td',{attrs:{"colspan":_vm.colspanValue}},[_c('div',{staticClass:"d-flex justify-center flex-wrap"},[_c('h5',{staticClass:"wsACCENT text-center font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$tc('TableSelectedMessage', _vm.allPaginationSelect && _vm.total ? _vm.total : _vm.selectedRows.length))+" ")]),_c('ws-button',{staticClass:"ml-2",attrs:{"label":_vm.allSelectedClearButtonCondition ? _vm.$t('ClearSelection') :  _vm.$tc('TableSelectAllText', _vm.total || _vm.inputItems.length),"color":_vm.wsATTENTION,"height":"18","rounded":"","text":"","text-regular":"","min-width":"10px"},on:{"click":_vm.selectAll}})],1)])]):_vm._e(),_vm._l((items),function(item,index){return [_vm._t("item",[_c('v-hover',{key:index + 'hover',scopedSlots:_vm._u([{key:"default",fn:function(ref){
       var hover = ref.hover;
return [_c('tr',{key:index,class:[{ 'pointer' : _vm.rowAction || _vm.selectedRows.length > 0 }],style:(_vm.getRowColor(hover, item.uuid)),on:{"click":function($event){return _vm.action(item)},"contextmenu":function($event){return _vm.openContextMenu($event, item)}}},[(_vm.selectable)?_c('td',{staticClass:"pl-4 pr-2",attrs:{"width":"10px"},on:{"click":function($event){$event.stopPropagation();return _vm.selectItem(item)}}},[_c('v-icon',{attrs:{"size":"18","color":_vm.wsACCENT}},[_vm._v("mdi-checkbox-"+_vm._s(_vm.isSelectedRow(item.uuid) ? 'marked' : 'blank')+"-outline")])],1):_vm._e(),_vm._l((_vm.headersFiltered),function(column){return [(column.align !== 'd-none')?_c('td',{key:'column' + index + column.value,class:[{ 'pa-0' : _vm.enableDrag && column.value === 'drag_handle' }],style:(_vm.cellStyle(column)),attrs:{"width":column.width}},[_vm._t('item.' + column.value,[_c('h5',{class:[{'text-center' : column.align === 'center'}]},[_vm._v(_vm._s(item[column.value]))])],{"item":item,"index":index,"hover":hover,"isSelected":_vm.isSelectedRow(item.uuid)}),_vm._t("item.drag_handle",[(_vm.enableDrag && column.value === 'drag_handle')?_c('v-sheet',{attrs:{"color":"transparent","width":"10px"}},[_c('v-fade-transition',[(hover)?_c('v-icon',{staticClass:"tableDragHandle ",style:(("cursor:" + (!_vm.drag ? 'grab' : 'grabbing')))},[_vm._v("mdi-drag-vertical")]):_vm._e()],1)],1):_vm._e()])],2):_vm._e()]})],2)]}}],null,true)}),(item.expanded)?_vm._t("expand",null,{"item":item,"index":index}):_vm._e()],{"item":item,"index":index,"isSelected":_vm.isSelectedRow(item.uuid),"contextAction":function ($event) { return _vm.openContextMenu($event, item); }})]})]:[_vm._l((items),function(item,index){return [_vm._t("item",[_c('v-hover',{key:index + 'hover',scopedSlots:_vm._u([{key:"default",fn:function(ref){
       var hover = ref.hover;
return [_c('tr',{key:index,class:[{ 'pointer' : _vm.rowAction }],style:(_vm.getRowColor(hover, item.uuid)),on:{"click":function($event){return _vm.action(item)},"contextmenu":function($event){return _vm.openContextMenu($event, item)}}},_vm._l((_vm.headersFiltered),function(column){return _c('td',{key:'column' + index + column.value,class:[{ 'pa-0' : _vm.enableDrag && column.value === 'drag_handle' }],style:(_vm.cellStyle(column)),attrs:{"width":column.width}},[_vm._t('item.' + column.value,[_vm._v(" "+_vm._s(item[column.value])+" ")],{"item":item,"index":index,"hover":hover,"isSelected":_vm.isSelectedRow(item.uuid)}),_vm._t("item.drag_handle",[(_vm.enableDrag && column.value === 'drag_handle')?_c('v-sheet',{attrs:{"color":"transparent","width":"10px"}},[_c('v-fade-transition',[(hover)?_c('v-icon',{staticClass:"tableDragHandle ",style:(("cursor:" + (!_vm.drag ? 'grab' : 'grabbing')))},[_vm._v("mdi-drag-vertical")]):_vm._e()],1)],1):_vm._e()])],2)}),0)]}}],null,true)})],{"item":item,"index":index,"isSelected":_vm.isSelectedRow(item.uuid),"contextAction":function ($event) { return _vm.openContextMenu($event, item); }})]})]],2),(!_vm.noFooter)?_c('tfoot',[_c('tr',[_c('td',{staticClass:"pa-0 ma-0",staticStyle:{"height":"1px !important"},attrs:{"height":"1px","colspan":_vm.colspanValue}},[_c('v-divider',{staticClass:"mb-3",style:(("border-color: " + _vm.wsBACKGROUND))})],1)])]):_vm._e()]}},(!_vm.noFooter)?{key:"footer",fn:function(ref){
       var props = ref.props;
return [_vm._t("footer.prepend"),_vm._t("footer",[_c('portal',{attrs:{"to":_vm.footerPortal,"disabled":!_vm.footerPortal}},[(_vm.displayPaginationCondition(props))?_c('v-sheet',{staticClass:"py-3 pl-4  d-flex flex-wrap align-center",attrs:{"color":"transparent"}},[_c('ft-select',{staticClass:"mr-6",attrs:{"items":_vm.paginationSelect(props),"width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('h5',[_vm._v(" "+_vm._s(_vm.$t('RowsPerPage'))+" : "+_vm._s(text ? text.text : 25))]),_c('v-icon',[_vm._v("mdi-menu-down")])]}}],null,true),model:{value:(props.options.itemsPerPage),callback:function ($$v) {_vm.$set(props.options, "itemsPerPage", $$v)},expression:"props.options.itemsPerPage"}}),_c('div',{staticClass:"d-flex  align-center"},[_c('v-btn',{attrs:{"disabled":props.options.page <2,"icon":"","color":_vm.wsACCENT},on:{"click":function($event){props.options.page--}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chevron-left")])],1),_c('h5',{staticClass:"mx-4"},[_vm._v(_vm._s(_vm.$t('RowsDisplayed'))+" "+_vm._s(props.pagination.pageStart + 1)+" - "+_vm._s(props.pagination.pageStop)+" із "+_vm._s(_vm.total || _vm.items.length))]),_c('v-btn',{attrs:{"disabled":(!_vm.total && props.options.page >= props.pagination.pageCount) || (_vm.total && _vm.total <= props.pagination.pageStop ) || _vm.disableLoadMore,"icon":"","color":_vm.wsACCENT},on:{"click":function($event){return _vm.handleLoadMore(props)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chevron-right")])],1)],1)],1):_c('div',[_c('h5',{staticClass:"text-left pr-8"},[_vm._v(" "+_vm._s(_vm.$t('RowsDisplayed'))+" : "+_vm._s(_vm.items.length)+" ")])])],1)])]}}:null],null,true)})],1),(_vm.contextActionsSelect.length > 0)?_c('ft-select',{attrs:{"items":_vm.contextActionsSelect,"expanded":_vm.displayContextMenu,"x":_vm.x,"y":_vm.y,"absolute":""},on:{"input":function($event){return _vm.contextAction($event)},"expand":function($event){_vm.displayContextMenu = $event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }