<template>
  <div class="overflow-x-auto">
    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
     <v-data-table
         @current-items="$emit('current-items' , $event); currentItems = $event"
         :items="items"
         :headers="headersFiltered"
         :search="search"
         :options="{
           page : page
         }"
         :items-per-page="4"
         :footer-props="{ itemsPerPageOptions : [25,50,100,-1]}"
         hide-default-header
         hide-default-footer
         :disable-pagination="disablePagination"
         class="wsHoverLight"
         :class="[{'pb-4' : !noFooter}]"
         :dense="dense"
         key="table"
         @pagination="handlePaginationData"


     >
    <template v-slot:header="{ props , on }">
      <slot name="header"
            :props="props" :on="on"
            :current-items="currentItems"
            :selectable="selectable"
            :all-selected="allSelected"
            :semi-selected="selectedRows.length"
            :enable-drag="enableDrag"
            :multiple-actions="multipleActions"
            :colspan-value="colspanValue"
            :count-selected="countSelected"
            :count-total="countTotal"
      >
        <ws-table-header
            v-if="!noHeader"
            @select-all="selectAllOnPage"
            :props="props"
            :on="on"
            :selectable="selectable"
            :all-selected="allSelected"
            :semi-selected="selectedRows.length > 0"
            :enable-drag="enableDrag"
            :multiple-actions="multipleActions"
            :colspan-value="colspanValue"
            :count-selected="countSelected"
            :count-total="countTotal"
        >
          <template #header.after>
            <slot name="header.after" :colspan-value="colspanValue"></slot>
          </template>
        </ws-table-header>
      </slot>
    </template>

    <template #body="{items}">


      <draggable
          v-model="currentItems"
          :forceFallback="true"
          :empty-insert-threshold="120"
          tag="tbody"
          handle=".tableDragHandle"
          v-bind="opt"
          @start="drag = true"
          @end="drag = false; finishDrag($event)"

      >

        <template v-if="!enableDrag">
          <tr
              v-if="selectable && selectedRows.length > 0"
          >
            <td :colspan="colspanValue">
              <div class="d-flex justify-center flex-wrap">
                <h5 class="wsACCENT text-center font-weight-regular">
                  {{ $tc('TableSelectedMessage', allPaginationSelect && total ? total : selectedRows.length) }}
                </h5>
                <ws-button
                    @click="selectAll"
                    :label="allSelectedClearButtonCondition ? $t('ClearSelection') :  $tc('TableSelectAllText', total || inputItems.length)"
                    :color="wsATTENTION"
                    class="ml-2"
                    height="18"
                    rounded text
                    text-regular
                    min-width="10px"
                />
              </div>

            </td>

          </tr>
          <template v-for="(item,index) in items" >
            <slot  @contextmenu="openContextMenu($event, item)"  name="item" :item="item" :index="index"
                   :isSelected="isSelectedRow(item.uuid)"
                   :context-action="($event) => openContextMenu($event, item)">
              <v-hover v-slot="{hover}" :key="index + 'hover'">
                <tr :style="getRowColor(hover, item.uuid)"
                    :class="[{ 'pointer' : rowAction || selectedRows.length > 0 }]"
                    @click="action(item)"
                    @contextmenu="openContextMenu($event, item)"
                    :key="index">

                  <td @click.stop="selectItem(item)" v-if="selectable" width="10px" class="pl-4 pr-2">
                    <v-icon size="18" :color="wsACCENT">mdi-checkbox-{{ isSelectedRow(item.uuid) ? 'marked' : 'blank' }}-outline</v-icon>
                  </td>

                  <template v-for="column in headersFiltered" >

                    <td
                        v-if="column.align !== 'd-none'"
                        :style="cellStyle(column)"
                        :key="'column' + index + column.value"
                        :width="column.width"
                        :class="[{ 'pa-0' : enableDrag && column.value === 'drag_handle' }]"
                    >
                      <slot :name="'item.' + column.value " :item="item" :index="index" :hover="hover" :isSelected="isSelectedRow(item.uuid)"  >
                        <h5 :class="[{'text-center' : column.align === 'center'}]">{{ item[column.value] }}</h5>
                      </slot>

                      <slot name="item.drag_handle">
                        <v-sheet color="transparent" width="10px" v-if="enableDrag && column.value === 'drag_handle'">
                          <v-fade-transition>
                            <v-icon  :style="`cursor:${ !drag ? 'grab' : 'grabbing' }`" class="tableDragHandle " v-if="hover">mdi-drag-vertical</v-icon>
                          </v-fade-transition>
                        </v-sheet>

                      </slot>


                    </td>
                  </template>

                </tr>
              </v-hover>
              <slot v-if="item.expanded" name="expand" :item="item" :index="index"></slot>

            </slot>

          </template>
        </template>
        <template v-else>
          <template v-for="(item,index) in items" >
            <slot  @contextmenu="openContextMenu($event, item)"  name="item" :item="item" :index="index"
                   :isSelected="isSelectedRow(item.uuid)"
                   :context-action="($event) => openContextMenu($event, item)">
              <v-hover v-slot="{hover}" :key="index + 'hover'">
                <tr :style="getRowColor(hover, item.uuid)"
                    :class="[{ 'pointer' : rowAction }]"
                    @click="action(item)"
                    @contextmenu="openContextMenu($event, item)"
                    :key="index">

                  <td v-for="column in headersFiltered"
                      :style="cellStyle(column)"
                      :key="'column' + index + column.value"
                      :width="column.width"
                      :class="[{ 'pa-0' : enableDrag && column.value === 'drag_handle' }]"
                  >
                    <slot :name="'item.' + column.value " :item="item" :index="index" :hover="hover" :isSelected="isSelectedRow(item.uuid)"  >
                      {{ item[column.value] }}
                    </slot>

                    <slot name="item.drag_handle">
                      <v-sheet color="transparent" width="10px" v-if="enableDrag && column.value === 'drag_handle'">
                        <v-fade-transition>
                          <v-icon  :style="`cursor:${ !drag ? 'grab' : 'grabbing' }`" class="tableDragHandle " v-if="hover">mdi-drag-vertical</v-icon>
                        </v-fade-transition>
                      </v-sheet>

                    </slot>


                  </td>
                </tr>
              </v-hover>
            </slot>

          </template>
        </template>


      </draggable>

      <tfoot v-if="!noFooter">
      <tr>
        <td height="1px" style="height: 1px !important;" :colspan="colspanValue" class="pa-0 ma-0">
          <v-divider class="mb-3" :style="`border-color: ${wsBACKGROUND}`" />
        </td>
      </tr>

      </tfoot>

    </template>

    <template v-if="!noFooter" #footer="{props}">
      <slot name="footer.prepend">
      </slot>
      <slot name="footer">

        <portal :to="footerPortal" :disabled="!footerPortal">
          <v-sheet v-if="displayPaginationCondition(props)"
                   class="py-3 pl-4  d-flex flex-wrap align-center"
                   color="transparent" >
            <ft-select class="mr-6"
                       :items="paginationSelect(props)"
                       v-model="props.options.itemsPerPage"
                       width="100px">
              <template #default="text">
                <h5> {{ $t('RowsPerPage') }} : {{ text ? text.text : 25 }}</h5>
                <v-icon>mdi-menu-down</v-icon>
              </template>

            </ft-select>
            <div class="d-flex  align-center">
              <v-btn @click="props.options.page--"
                     :disabled="props.options.page <2"
                     icon :color="wsACCENT"><v-icon large>mdi-chevron-left</v-icon></v-btn>
              <h5 class="mx-4">{{ $t('RowsDisplayed') }} {{ props.pagination.pageStart + 1 }} - {{ props.pagination.pageStop }} із
                {{ total || items.length }}</h5>
              <v-btn @click="handleLoadMore(props)"
                     :disabled="(!total && props.options.page >= props.pagination.pageCount) || (total && total <= props.pagination.pageStop ) || disableLoadMore "
                     icon :color="wsACCENT"><v-icon large>mdi-chevron-right</v-icon></v-btn>
            </div>
          </v-sheet>

          <div v-else>
            <h5  class="text-left pr-8">
              {{ $t('RowsDisplayed') }} : {{ items.length }}
            </h5>
          </div>
        </portal>

      </slot>
    </template>


  </v-data-table>
    </transition-group>
     <ft-select
         v-if="contextActionsSelect.length > 0"
         @input="contextAction($event)"
         :items="contextActionsSelect"
         :expanded="displayContextMenu"
         @expand="displayContextMenu = $event"
         :x="x"
         :y="y"
         absolute
     />
  </div>

</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "wsDataTable",
  components : {
    draggable
  },
  props : {
    loadMore : {
      type : Function
    },
    headers : {
      type : Array,
      default : () => []
    },
    items : {
      type : Array,
      default : () => []
    },
    multipleActions : {
      type : Array,
      default : () => []
    },
    contextActionsSelect : {
      type : Array,
      default : () => []
    },
    search : {
      type : String,
      default : ''
    },
    dense : {
      type : Boolean,
      default : false
    },
    noHeader : {
      type : Boolean,
      default : false
    },
    noFooter : {
      type : Boolean,
      default : false
    },
    rowAction : {
      type : Function,
    },
    rowContextAction : {
      type : Function,
    },
    disablePagination : {
      type : Boolean,
      default : false
    },
    selectedRow : {
      type : String
    },
    selectedRows : {
      type : Array,
      default() { return [] }
    },
    enableDrag : {
      type : Boolean,
      default : false
    },
    footerPortal : {
      type : String
    },
    total : {
      type : Number
    },
    selectable : {
      type : Boolean,
      default : false
    },
  },
  data() {
    return {
      pagination: {},
      disableLoadMore : false,
      displayContextMenu : false,
      x : 0,
      y : 0,
      selectedItem : {},
      currentItems : [],
      drag : false,
      opt : {
        animation: 200,
        ghostClass: "ghost"
      },
      itemsPerPage : 12,
      allPaginationSelect : false,

      page : 1,
    }
  },
  computed : {
    countSelected() {
      return this.allPaginationSelect ? this.total : this.selectedRows.length
    },
    countTotal() {
      if ( this.items.length < this.total) {
        return this.total
      }
      return this.items.length
    },
    allSelectedClearButtonCondition() {
      if (  this.items.length < this.total && this.allPaginationSelect ) {
        return true
      } else if (!this.total && this.allSelected) {
        return true
      }
      return false
    },
    inputItems() {
      return this.items
    },
    allSelected() {
      return this.items.length === this.selectedRows.length
    },
    allOnPageSelected() {
      const selectedFromPage = this.selectedRows.filter(el => this.currentItems.map(item => item.uuid).includes(el) )
      return this.currentItems.length === selectedFromPage.length
    },
    headersFiltered() {

      let headers = this.headers
      if ( headers.length === 0) {
        return []
      }
     // headers = headers.filter(el => el.align !== 'd-none')

      if ( this.enableDrag ) {
        headers.unshift({
          value : 'drag_handle' , sortable : false , width : 1
        })
      }

      return headers

    },
    colspanValue() {
      let value = this.headers.length
      if ( this.selectable) {
        value += 1
      }
      if (this.enableDrag) {
        value += 1
      }
      return value
    }
  },
  methods : {
    gotoPage(page) {
      this.page = page
    },
    handlePaginationData($event) {
      this.pagination = $event
      this.page = $event.page
      this.itemsPerPage = $event.itemsPerPage
    },
    selectAllOnPage() {
      let select = this.COPY(this.selectedRows)

      if ( !this.allOnPageSelected ) {
        this.currentItems.forEach(item => {
            if ( !select.includes(item.uuid) ) {
              select.push(item.uuid)
            }
        })
      } else {
        this.allPaginationSelect = false
        select = select.filter(el => !this.currentItems.map(item=>item.uuid).includes(el) )
      }

      this.$emit('select' , {items : select})
    },
    selectAll() {
      if ( this.total && this.items.length < this.total) {
        if ( !this.allPaginationSelect) {
          this.allPaginationSelect = true
          this.$emit('select' , {items : this.items.map(el => el.uuid) , selectAll : this.allPaginationSelect})
        } else {
          this.allPaginationSelect = false
          this.$emit('select' , {items : [] , selectAll : false})
        }
      } else {
        this.allPaginationSelect = false
        let select = this.allSelected ? [] : this.items.map(el => el.uuid)
        this.$emit('select' , {items : select , selectAll : this.allPaginationSelect})
      }

    },

    selectItem(item) {
      let selected = this.COPY(this.selectedRows)
      if ( selected.includes(item.uuid) ) {
        this.allPaginationSelect = false
        selected = selected.filter(el => el !== item.uuid)

      } else {
        selected.push(item.uuid)
      }

      this.$emit('select' , {items : selected})
    },
    async handleLoadMore(props) {
      if ( this.total && props.pagination.pageStop < this.total) {
        this.disableLoadMore = true
        await this.loadMore(props.pagination.pageStop , props.options.itemsPerPage)
        this.disableLoadMore = false
        props.options.page++
      } else {
        props.options.page++
      }

    },

    cellStyle(header){
      let style = `border-color: ${this.wsBACKGROUND};`

      if ( header.padding || header.padding === 0 ) {
        style += `padding-left  : ${header.padding}px !important;`
        style += `padding-right : ${header.padding}px !important;`
      }

      if ( header.borderLeft ) {
        style += `border-left: 1px solid ${this.wsBACKGROUND} !important;`
      }

      if ( header.borderRight) {
        style += `border-right: 1px solid ${this.wsBACKGROUND} !important;`
      }

      return style
    },
    finishDrag() {
      this.$emit('table-drag-end' , this.currentItems)
    },
    contextAction($event ) {
      this.$emit('contextAction' , $event,  this.selectedItem);
      this.$emit('itemSelect' , this.selectedItem)
    },
    getRowColor(hover , uuid) {
      if ( this.selectedRows.includes(uuid) ) {
        return `background : ${ this.wsLIGHTCARD } !important`
      }
      if ( this.isSelectedRow(uuid) ) {
        return `background : ${this.wsLIGHTCARD} !important`
      }
      return `background : ${ hover ? this.wsLIGHTCARD : ''} !important`
    },
    isSelectedRow( uuid) {
      if ( this.selectedRows && this.selectedRows.includes(uuid) ) {
        return true
      }
      return !!this.selectedRow && this.selectedRow === uuid
    },
    openContextMenu($event , item) {
      if ( this.contextActionsSelect.length === 0 ) {
        return
      }
      if ( item.prevent_context ) {
        return
      }

      this.selectedItem = item
      this.$emit('select-item' , item)
      $event.preventDefault()
      this.displayContextMenu = false
      this.x = $event.clientX
      this.y = $event.clientY
      this.$nextTick(() => {
        this.displayContextMenu = true
      })

      if ( this.contextActionsSelect.length > 0  ) {
        this.displayContextMenu = true
      }
    },
    action(item) {
      if ( this.selectedRows.length > 0) {
        this.selectItem(item)
        return
      }
      if ( this.rowAction ) {
        this.rowAction(item)
      }
    },

    paginationSelect(props) {
      let items = []
      if ( props.itemsPerPageOptions) {
        props.itemsPerPageOptions.forEach(item=>{
          items.push( { text : (item !== -1 ? item : this.$t('All'))  , value : item  })
        })
      }
      return items
    },
    displayPaginationCondition(props) {
      let page = props.pagination.pageCount || 1
      if ( this.total && this.total > (page * (this.itemsPerPageIndex(props) || 1 ))  ) {
        return true
      }
      if ( this.disablePagination ) {
        return false
      }
      return props.pagination.pageCount > 1 || this.itemsPerPageIndex(props) !== 0
    },
    itemsPerPageIndex(props) {
      return props.itemsPerPageOptions.findIndex( el => el === props.options.itemsPerPage)
    },
    currentItemsPerPage(props) {
      return props.itemsPerPageOptions.find( el => el === props.options.itemsPerPage)
    },

  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>